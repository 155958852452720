import React from "react";

interface TooltipProps {
  rounded?: boolean;
  text?: string;
}

const TheTooltip = ({ rounded, text }: TooltipProps) => (
  <div className="flex flex-col-reverse justify-center">
    <div className="flex items-end justify-center w-full h-2 overflow-hidden duration-300 animation">
      <div
        className={`w-3 h-3 mb-0.5 rotate-45 bg-secondaryYellow ${
          rounded ? `rounded-br-md` : "rounded-br-none"
        }`}
      />
    </div>
    <div
      className={`justify-center w-full px-5 py-1 text-center duration-300 bg-secondaryYellow animation ${
        rounded ? "rounded-xl" : "rounded-none"
      }`}
    >
      <div>{text ? `${text}` : `Hi There I'm`}</div>
    </div>
  </div>
);
export default TheTooltip;
