import React from "react";

interface FooterProps {
  className?: string;
}

function FooterLayout({ className }: FooterProps) {
  let copy = <span>&copy; </span>;
  let year = new Date().getFullYear();
  return (
    <div className={`${className} py-3 bg-white fixed-bottom`}>
      <div className="text-sm font-light text-center flex-cc">
        <span>
          {copy} {year} Badaruddin Luthfi. All Rights Reserved.
        </span>
      </div>
    </div>
  );
}

export default FooterLayout;
