import NavbarLayout from "./NavbarLayout";
import FooterLayout from "./FooterLayout";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <>
      <NavbarLayout />
      {children}
      <FooterLayout />
    </>
  );
};
export default MainLayout;
