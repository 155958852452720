// import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Emoji from "../Component/Emoji";

export default function NavbarLayout() {
  let pages = [
    {
      path: "/",
      name: "Badarudd.in",
    },
    // {
    //   path: '/projects',
    //   name: 'Projects',
    // },
    // {
    //   path: "/achievement",
    //   name: "Achievement",
    // },
    // {
    //   path: '/about',
    //   name: 'About',
    // },
  ];
  return (
    <nav className="bg-secondaryYellow fixed-top">
      <div className="flex">
        <ul className="flex flex-row text-sm">
          <Link to={"/"}>
            <li className="px-5 py-5 font-medium text-black duration-200 hover:bg-primaryYellow dark:text-black">
              <img className="w-5" src="/Logo.svg" alt="" />
            </li>
          </Link>
        </ul>
        <ul className="flex flex-row ml-auto text-sm">
          {pages.map((page, index) => (
            <Link to={page.path} aria-current="page">
              <li
                key={index}
                className={`${
                  window.location.pathname === page.path && " font-semibold "
                } px-3 py-5 font-medium text-black duration-200 hover:bg-primaryYellow dark:text-black`}
              >
                {/* {page.name === "Badarudd.in" && (
                  <Emoji
                    className="mr-2"
                    src="flag-indonesia.png"
                    label="indonesia"
                  />
                )} */}
                {page.name}
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </nav>
  );
}
