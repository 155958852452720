import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import TheTooltip from "../Component/Tooltip";
import MainLayout from "../Layout/MainLayout";

export default function Index() {
  document.title = "Hi There i'm Badaruddin";
  // const navigate = useNavigate();
  const [nameToolTip, setNameToolTip] = useState(false);
  const [underline, setUnderline] = useState(true);

  return (
    <main>
      <MainLayout>
        <div className="flex items-center justify-center w-full h-screen">
          <div className="flex flex-col-reverse w-full gap-6 px-4 space-x-0 md:gap-4 md:flex-row-reverse md:px-0 md:space-x-10">
            <div className="flex items-center justify-center w-full mt-5 text-center md:text-left md:justify-center md:w-2/3 md:mt-0">
              <div className="w-full md:w-1/2">
                <div className="relative mb-2">
                  <span
                    className={`${
                      underline ? "underline" : "no-underline"
                    } text-4xl font-bold cursor-default`}
                    // onClick={() => {
                    //   navigate("/about");
                    // }}
                    onMouseEnter={() => {
                      setNameToolTip(true);
                      setUnderline(false);
                    }}
                    onMouseLeave={() => {
                      setNameToolTip(false);
                      setUnderline(true);
                    }}
                  >
                    {nameToolTip && (
                      <span className="text-base font-normal">
                        <div className="absolute top-0 inline-flex -mt-10 -ml-10">
                          <TheTooltip rounded={true} />
                        </div>
                      </span>
                    )}
                    Badarudd
                  </span>
                  <span
                    className="px-1 py-0 text-4xl font-bold cursor-default bg-primaryYellow"
                    // onClick={() => {
                    //   navigate("/about");
                    // }}
                    onMouseEnter={() => {
                      setNameToolTip(true);
                      setUnderline(false);
                    }}
                    onMouseLeave={() => {
                      setNameToolTip(false);
                      setUnderline(true);
                    }}
                  >
                    in Luthfi
                  </span>
                </div>
                <div className="">
                  <p className="mb-2"> Web | Logo | Branding </p>
                  <p>
                    A Software Engineer with passion for graphic design with
                    logo design skills and some branding experience
                  </p>
                  <br />
                  {/* <p>
                  See my{' '}
                  <Link to={'projects'} className="underline hover:no-underline">
                    {' '}
                    projects
                  </Link>{' '}
                  or let's{' '}
                  <Link to={'about'} className="underline hover:no-underline">
                    know me
                  </Link>{' '}
                  more
                </p> */}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center w-full mb-5 md:justify-end md:w-1/3 md:mb-0">
              <div className="w-full md:w-1/2">
                <div className="flex items-center justify-center w-full mx-auto min-h-fit md:ml-0 md:w-1/2">
                  <div className="md:max-w-[300px] md:max-h-[300px] max-w-[400px] max-h-[400px]">
                    <div className="md:w-[300px] md:h-[300px] w-[300px] h-[300px] bg-primaryYellow rounded-full ml-0 md:ml-10 relative">
                      <div className="md:w-[150px] md:h-[150px] w-[120px] md:mr-0 -mr-4 h-[120px] bg-secondaryYellow rounded-full absolute right-0 opacity-50" />
                      <div className="md:w-[150px] md:h-[150px] w-[120px] md:mr-0 -mr-4 h-[120px] bg-secondaryYellow rounded-full absolute right-0 -z-10" />
                      {/* <div className="w-[150px] h-[150px] bg-white outline-2 outline rounded-full absolute bottom-0 -z-10 -ml-5 -mb-5" /> */}
                      <img
                        style={{ objectFit: "cover" }}
                        className="ml-0 md:-ml-10"
                        src="/transperant.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </main>
  );
}
