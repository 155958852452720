import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./Pages/Index";
// import Login from "./Pages/Login";
// import Projects from "./Pages/Project";
// import Achievement from "./Pages/Achievement";
// import About from "./Pages/About";
import React from "react";

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        {/* <Route path="/projects" element={<Projects />} />
        <Route path="/achievement" element={<Achievement />} /> */}
        {/* <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
